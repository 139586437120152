@font-face {
  font-family:'Benton Sans';
  src: local('Benton Sans'), url(./assets/font/BentonSans/BentonSans-Regular.otf) format('opentype');
  font-style:normal;
  font-weight:normal
}
@font-face {
  font-family:'Benton Sans Light';
  src: local('Benton Sans Light'), url(./assets/font/BentonSans/BentonSans-Light.otf) format('opentype');
  font-style:normal;
  font-weight:normal
}
@font-face {
  font-family:'Benton Sans Bold';
  src: local('Benton Sans Bold'), url(./assets/font/BentonSans/BentonSans-Bold.otf) format('opentype');
  font-style:normal;
  font-weight:bold
}
@font-face {
  font-family:'Benton Sans Italic';
  src: local('Benton Sans Italic'), url(./assets/font/BentonSans/BentonSans-Regular.otf) format('opentype');
  font-style:italic;
  font-weight:normal
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: #656972;
  margin: 0;
  padding: 0;
  font-family: 'Benton Sans';
}
