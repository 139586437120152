.wrapper .oauth-wrapper .header {
  font-family: 'Benton Sans Bold', Helvetica, Arial, sans-serif;
  font-size: 30px;
  color: #4E5056;
  letter-spacing: -1px;
  line-height: 44px;
}

.wrapper .oauth-wrapper .body-text {
  font-size: 18px;
  margin: 0 0 40px;
}

.wrapper .oauth-wrapper form {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.wrapper .oauth-wrapper form input.submit{
  background: #4DB5AB;
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0;
  max-width: 400px;
  height: 65px;
  border: none;
  margin: 20px 0 40px;
  cursor: pointer;
  border-radius: 2px;
}

.wrapper .oauth-wrapper form input.submit:disabled{
  background: #9CD5CE;
}

.wrapper .oauth-wrapper form .dialog-inputs input.deny{
  background: #4E5056;
}

.wrapper .oauth-wrapper form div.errors{
  padding: 10px 0 0 0;
  margin: 0;
  font-family: 'Benton Sans', Helvetica, Arial, sans-serif;
  font-size: 18px;
  color:#E31212;
  list-style: none;
}

.wrapper .oauth-wrapper form div.success{
  padding: 0;
  margin: 0;
  font-family: 'Benton Sans Bold', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  color:#009900;
  list-style: none;
}
