body {
  font-size: 14px;
  font-family: 'Benton Sans', Helvetica, Arial, sans-serif;
}

a {
  color: #00B7FF;
}

.wrapper{
  width: 100%;
}

.wrapper .oauth-wrapper {
  padding: 40px 0;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}

.wrapper .oauth-wrapper {
  margin-bottom: 60px;
}

.wrapper .oauth-wrapper .vizio-logo {
  margin-bottom: 60px;
  width: 69px;
  height: 15.5px;
  opacity: 0.25;
  margin: 0 auto;
}

.wrapper .oauth-wrapper .header{
  font-family: 'Benton Sans Bold', Helvetica, Arial, sans-serif;
  font-size: 40px;
  color: #4E5056;
  letter-spacing: -1px;
  line-height: 44px;
  margin-bottom: 40px;
}

.wrapper .oauth-wrapper.dialog .header{
  margin-bottom: 20px;
}

.wrapper .oauth-wrapper .sub-header{
  font-size: 18px;
  color: #656972;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 35px;
}

.wrapper .oauth-wrapper form{
  max-width: 500px;
  margin: 0 auto;
}

.wrapper .oauth-wrapper form .field-group{
  margin-bottom: 40px;
}

.wrapper .oauth-wrapper form .field-group.last{
  margin-bottom: 20px;
}

.wrapper .oauth-wrapper form .field-group label{
  display: block;
  text-align: left;
  font-size: 20px;
  color: #4DB5AB;
  margin-bottom: 15px;
  letter-spacing: 0;
}

.wrapper .oauth-wrapper form .field-group input.text{
  width: calc(100% - 40px);
  height: 8px;
  border: 2px solid #E5E7EA;
  padding: 20px;
  letter-spacing: 2px;
  font-size: 16px;
}

.wrapper .oauth-wrapper form .field-group input.pin{
  width: calc(100% - 40px);
  height: 8px;
  border: 2px solid #E5E7EA;
  padding: 20px;
  letter-spacing: 2px;
  font-size: 16px;
}

.wrapper .oauth-wrapper form input.submit{
  background: #4DB5AB;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  width:100%;
  height: 65px;
  border: none;
  margin: 20px 0 40px;
  cursor: pointer;
}

.wrapper .oauth-wrapper form .dialog-inputs{
  display: flex;
  justify-content: space-between;
}

.wrapper .oauth-wrapper form .dialog-inputs input{
  flex:1;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  background: #4DB5AB;
  height: 65px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
}

.wrapper .oauth-wrapper form .dialog-inputs input.deny{
  background: #4E5056;
}

.wrapper .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 11px;
  color: #84878e;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.wrapper .oauth-wrapper form ul.errors{
  padding: 0;
  margin: 0;
}

.wrapper .oauth-wrapper form ul.errors li{
  font-family: 'Benton Sans Bold', Helvetica, Arial, sans-serif;
  font-size: 11px;
  color:#D4503F;
  list-style: none;
  text-align: left;
}

.wrapper input{
  font-family: 'Benton Sans';
}