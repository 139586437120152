.check-container.error {
  width: 72px;
  height: 72px;
  margin: 0 auto;
}

.modal--body {
  font-family: 'Benton Sans', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.retry {
  display: block;
  background: #4DB5AB;
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0;
  max-width: 400px;
  height: 65px;
  line-height: 65px;
  border: none;
  margin: 25px auto;
  cursor: pointer;
  border-radius: 2px;
}
