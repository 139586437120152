a {
  color: #4db6ac;
  font-weight: 700;
  text-decoration: none;
}

.check-container.success {
  width: 64px;
  height: 64px;
  margin: 0 auto;
}

.title {
  color: #4e5056;
  margin-top: 35px;
  margin-bottom: 35px;
  font-family: 'Benton Sans Bold';
  font-size: 30px;
}

.body-text {
  line-height: 24px;
}

.modal--body {
  margin-top: 25px;
}

.modal--body ol {
  padding: 0;
  margin-top: 1.5em;
  list-style-position: inside;
}

.modal--body li {
  margin: 0.7em 0;
}

.btn {
  line-height: 65px;
  background-color: #4db6ac;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 2px;
  margin: 30px 0;
  cursor: pointer;
}

.add-device {
  font-family: 'Benton Sans Light';
  font-size: 18px;
}
