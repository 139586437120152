.pin-input-group input.text {
  width: 20%;
  max-width: 64px;
  height: 76px;
  margin: 5px;
  padding: 10px;
  font-size: 22px;
  border: 2px solid #E5E7EA;
  font-weight: bold;
  text-align: center;
}

.pin-input-group input.text.empty {
  background: #f9f9f9;
}

.pin-input-group input.text:first-child {
  margin-left: 0;
}

.pin-input-group input.text:last-child {
  margin-right: 0;
}

.pin-input-group.error input.text {
  border: 2px solid #E31212;
  color: #D4503F;
}

@media (min-width: 420px) {
  .pin-input-group input.text {
    margin: 10px;
    padding: 20px;
  }
}
